import React, { Component } from 'react';
import {HeaderBanner} from "../components/HeaderBanner";
import {BecomeAWasherSection} from "../components/BecomeAWasherSection";
import rightImage from '../assets/images/header-icons/become-a-washer-right.svg';
import leftImage from '../assets/images/header-icons/become-a-washer-left.svg';
import {Header} from "../components/Header";

export class BecomeAWasher extends Component {
    render() {
        return (
            <React.Fragment>
                <Header routeProps={this.props} />
                <HeaderBanner
                    leftIcon={leftImage}
                    rightIcon={rightImage}>
                    <h1>Become a washer</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </HeaderBanner>
                <BecomeAWasherSection/>
            </React.Fragment>
        );
    }
}