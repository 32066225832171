import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import logo from '../../assets/images/logo.svg';
import logoALt from '../../assets/images/logo-minimal.svg';
import placeholderLangFlag from '../../assets/images/placeholder/flag.png';
import './style.scss';
import {Dropdown} from "react-bootstrap";

export class Header extends Component {
    constructor(props) {
        super(props);
        this.renderBecomeAWasher = this.renderBecomeAWasher.bind(this);
        this.renderLang = this.renderLang.bind(this);
        this.sideButtonLogic = this.sideButtonLogic.bind(this);
    }

    renderBecomeAWasher() {
        return (
            <div className="margin-left-auto header--auth-section">
                <a href="https://dashboard.washerly.com" className="header__login_button">Login</a>
                <a href="https://dashboard.washerly.com/sign-up" className="header__join_button">Work with us</a>
            </div>
        )
    };

    renderLang() {
        return (
            <Dropdown alignRight id="language-dropdown">
                <Dropdown.Toggle variant="language-dropdown">
                    <img src={placeholderLangFlag} alt="" /> English
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#">
                        <img src={placeholderLangFlag} alt="" /> Latvian
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                        <img src={placeholderLangFlag} alt="" /> Russian
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    };

    sideButtonLogic() {
        if(this.props.routeProps.location.pathname !== '/') {
            return this.renderLang();
        } else {
            return this.renderBecomeAWasher();
        }
    }

    render() {
        return (
            <header id="header">
                <div className="container container-fluid">
                    <div className="display-flex--items-center">
                        <div className="logo">
                            <Link to="/" className="display-inline-block"><img src={this.props.routeProps.location.pathname !== '/' ? logoALt : logo}/></Link>
                        </div>
                        <nav className="header-navigation-wrapper mr-auto">
                            <ul className={`header-navigation ${this.props.routeProps.location.pathname !== '/' ? 'dark' : ''}`}>
                                <li><NavLink to="/" exact activeClassName='active'>Home</NavLink></li>
                                <li><NavLink to="/faq" activeClassName='active'>Faq</NavLink></li>
                                <li><NavLink to="/contact" activeClassName='active'>Contact</NavLink></li>
                            </ul>
                        </nav>

                        { this.sideButtonLogic() }

                    </div>
                </div>
            </header>
        );
    }
}
