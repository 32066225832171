import React, { Component } from 'react';
import {HeaderBanner} from "../components/HeaderBanner";
import rightImage from '../assets/images/header-icons/terms-of-use-right.svg';
import leftImage from '../assets/images/header-icons/terms-of-use-left.svg';
import {Header} from "../components/Header";

export class TermsOfUse extends Component {
    render() {
        return (
            <React.Fragment>
                <Header routeProps={this.props} />
                <HeaderBanner
                    leftIcon={leftImage}
                    rightIcon={rightImage}>
                    <h1>Terms of Use</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </HeaderBanner>

                <section className="white-section py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-md-12 text-container">
                                <h2 className="h3 border-highlight">Lorem ipsum is a pseudo-Latin text used in web design, typography</h2>
                                <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and
                                    printing in place of English to emphasise design elements over content. It’s
                                    also called placeholder (or filler) text. It’s a convenient tool for mock-ups.
                                    It helps to outline the visual elements of a document or presentation, eg
                                    typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by
                                    the classical author and philosopher Cicero. Its words and letters have been
                                    changed by addition or removal, so to deliberately render its content nonsensical;
                                    it’s not genuine, correct, or comprehensible Latin anymore. While lorem ipsum’s
                                    still resembles classical Latin, it actually has no meaning whatsoever. </p>

                                <h2 className="h3 border-highlight">Lorem ipsum is a pseudo-Latin text used in web design, typography</h2>
                                <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and
                                    printing in place of English to emphasise design elements over content. It’s
                                    also called placeholder (or filler) text. It’s a convenient tool for mock-ups.
                                    It helps to outline the visual elements of a document or presentation, eg
                                    typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by
                                    the classical author and philosopher Cicero. Its words and letters have been
                                    changed by addition or removal, so to deliberately render its content nonsensical;
                                    it’s not genuine, correct, or comprehensible Latin anymore. While lorem ipsum’s
                                    still resembles classical Latin, it actually has no meaning whatsoever. </p>

                                <h2 className="h3 border-highlight">Lorem ipsum is a pseudo-Latin text used in web design, typography</h2>
                                <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and
                                    printing in place of English to emphasise design elements over content. It’s
                                    also called placeholder (or filler) text. It’s a convenient tool for mock-ups.
                                    It helps to outline the visual elements of a document or presentation, eg
                                    typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by
                                    the classical author and philosopher Cicero. Its words and letters have been
                                    changed by addition or removal, so to deliberately render its content nonsensical;
                                    it’s not genuine, correct, or comprehensible Latin anymore. While lorem ipsum’s
                                    still resembles classical Latin, it actually has no meaning whatsoever. </p>

                                <h2 className="h3 border-highlight">Lorem ipsum is a pseudo-Latin text used in web design, typography</h2>
                                <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and
                                    printing in place of English to emphasise design elements over content. It’s
                                    also called placeholder (or filler) text. It’s a convenient tool for mock-ups.
                                    It helps to outline the visual elements of a document or presentation, eg
                                    typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by
                                    the classical author and philosopher Cicero. Its words and letters have been
                                    changed by addition or removal, so to deliberately render its content nonsensical;
                                    it’s not genuine, correct, or comprehensible Latin anymore. While lorem ipsum’s
                                    still resembles classical Latin, it actually has no meaning whatsoever. </p>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}