import React, { Component } from 'react';
import {HeaderBanner} from "../components/HeaderBanner";
import rightImage from '../assets/images/header-icons/faq-right.svg';
import leftImage from '../assets/images/header-icons/faq-left.svg';

import {Header} from "../components/Header";
import {Faq} from "../components/Faq";

export class FaqEntry extends Component {
    render() {
        return (
            <React.Fragment>
                <Header routeProps={this.props} />
                <HeaderBanner
                    leftIcon={leftImage}
                    rightIcon={rightImage}>
                    <h1>Faq</h1>
                    <p>Here you will find most frequently asked questions.</p>
                </HeaderBanner>
                <Faq props={this.props} />
            </React.Fragment>
        );
    }
}
