import '../../assets/animations/landing/image_import';
import './style.scss';

import React, { Component } from 'react';
import Lottie from 'react-lottie';

import animationData from '../../assets/animations/landing/data.json';
import BubblesLeftTop from '../../assets/images/bubbles_left_top.png';
import BubblesRight from '../../assets/images/bubbles_right.png';
import BubblesLeftBottom from '../../assets/images/bubbles_left_bottom.png';


export class BannerSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isStopped: true,
        }
    }

    render() {
        const lottieOptions = {
            loop: false,
            autoplay: true, 
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <React.Fragment>
                <section id="banner-section">
                    <div className="container h-100 content">
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <div className="row">
                                    <div className="col-12 col-md-8 banner-section--heading">
                                        <h1>Car wash <span className="color-lightgreen">on-demand</span></h1>
                                        <h2>In your mobile</h2>
                                    </div>
                                    <div className="col-4 language-picker-wrapper">
                                        <span className="language-picker">
                                            English
                                        </span>
                                    </div>
                                </div>
                                <div className="row banner-section--description">
                                    <div className="col-12">
                                        <p>Washerly is an eco-friendly waterless car wash platform that works on-demand. Commercial car washing companies use around 13248 liters of water per day which translates to 4.76 million liters per year on average. Water scarcity is a real problem and at Washerly we take it as seriously as it needs to be taken.</p>
                                        <p>Washerly users can order our car cleaning services from anywhere using the Washerly mobile app. By downloading the Washerly app you can save time, money and the planet, all at once.</p>
                                    </div>
                                </div>
                                <div className="position-relative margin-top-10">
                                    <div className="row header-download-buttons">
                                        <div className="col-12 col-md-3">
                                            <h4 className="header-download-heading">Download the app now.</h4>
                                        </div>
                                        <div className="col-12 col-md-9">
                                            <div className="button__download-wrapper">
                                                <a href="https://apps.apple.com/lv/app/washerly/id1504687670" className="button__download ios" target="_blank">
                                                    <span className="button-text">
                                                        <span className="subtitle">Download on the</span> 
                                                        <span className="title">App Store</span>
                                                    </span>
                                                    <span className="bubbles-top"></span>
                                                    <span className="bubbles-bottom"></span>
                                                </a>
                                            </div>

                                            <div className="button__download-wrapper">
                                                <a href="https://play.google.com/store/apps/details?id=com.carshine.washerly" target="_blank" className="button__download android">
                                                    <span className="button-text">
                                                        <span className="subtitle">Get it on</span>
                                                        <span className="title">Play market</span>
                                                    </span>
                                                    <span className="bubbles-right"></span>
                                                    <span className="bubbles-left"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="banner-section-right-image-wrapper">
                                    <Lottie 
                                        options={lottieOptions}
                                        isStopped={this.state.isStopped}
                                        eventListeners={[
                                            {
                                              eventName: 'loaded_images',
                                              callback: () => this.setState({ isStopped: false }),
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bubbles-overlay-wrapper">
                        <div className="bubbles-left-top">
                            <img src={BubblesLeftTop}/>
                        </div>
                        <div className="bubbles-left-bottom">
                            <img src={BubblesLeftBottom}/>
                        </div>
                        <div className="bubbles-right">
                            <img src={BubblesRight}/>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
