import React, { Component } from 'react';
import { Link } from "react-router-dom";

import logo from '../../assets/images/logo.svg';

import FacebookLogo from '../../assets/images/facebook.svg';
import InstagramLogo from '../../assets/images/instagram.svg';
import TwitterLogo from '../../assets/images/twitter.svg';

import './style.scss';

export class Footer extends Component {
    render() {
        return (
            <section id="footer">
                <div className="container content">
                    <div className="row footer__top_section">
                        <div className="col-12 col-md-3 footer__logo">
                            <img src={logo}/>
                        </div>
                        <div className="col-7 footer__address">
                            <div className="address__wrapper">
                                <span className="address__icon"></span>
                                <div className="address__text">
                                    <p>SIA “CARSHINE”</p>
                                    <p>Rūpniecibas street 32B - 9,</p>
                                    <p>Riga</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 footer__auth">
                            <a href="https://dashboard.washerly.com" className="footer__login__button">Login</a>
                            <a href="https://dashboard.washerly.com/sign-up" className="footer__become_member__button">Work With Us</a>
                        </div>
                    </div>
                    <div className="row">
                        <hr className="footer__divider"/>
                    </div>
                    <div className="row footer_bottom_section">
                        <div className="col-12 col-md-3 footer__social_outer_wrapper">
                            <div className="row mobile__center footer__social_wrapper">
                                <ul className="footer__social">
                                    <li><a href="#"><img src={FacebookLogo}/></a></li>
                                    <li><a href="https://www.instagram.com/washerlyapp/"><img src={InstagramLogo}/></a></li>
                                    <li><a href="https://twitter.com/washerlyapp"><img src={TwitterLogo}/></a></li>
                                </ul>
                            </div>
                            <div className="row mobile__center">
                                Copyright 2020 Ⓒ CARSHINE
                            </div>
                        </div>
                        <div className="col-12 col-md-6 footer__links">
                            <ul>
                                <li><Link to="/privacy-policy">Privacy policy</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 registration__button_wrap">
                            <a href="https://dashboard.washerly.com" className="auth__button">Login</a>
                            <a href="https://dashboard.washerly.com/sign-up" className="auth__button">Become a member</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
