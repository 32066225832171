import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './style.scss';
import facebook from '../../assets/images/social-icons/soc-facebook.svg';
import twitter from '../../assets/images/social-icons/soc-twitter.svg';
import instagram from '../../assets/images/social-icons/soc-instagram.svg';
import placeholderThumbnail from '../../assets/images/placeholder/blog-article-thumbnail.png';

export class BlogArticle extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <article className="blog-article">
                <div className="blog-article-image" style={{backgroundImage: `url(${placeholderThumbnail})`}}>
                    <div className="badge badge-new">New</div>
                </div>
                <div className="blog-article-details">
                    <span className="date">02 April 2020</span>
                    <Link className="title" to="/blog/blog-entry-url">Why yo should switch to car wash on-demand?</Link>
                    <p className="m-0">Washerly is an eco-friendly waterless car wash platform that works on-demand. Commercial car washing companies use around 13248 liters of water per day which translates to 4.76 million liters per year on average. Water scarcity is a real problem and at Washerly we take it as seriously as it needs to be taken.

Washerly users can order our car cleaning services from anywhere using the Washerly mobile app. By downloading the Washerly app you can save time, money and the planet, all at once.</p>
                </div>
                <div className="blog-article-actions">
                
                    <ul className="social-networks">
                        <li><a href="#"><img src={facebook} alt="Facebook" /></a></li>
                        <li><a href="https://twitter.com/washerlyapp"><img src={twitter} alt="Twitter" /></a></li>
                        <li><a href="https://www.instagram.com/washerlyapp/"><img src={instagram} alt="Instagram" /></a></li>
                    </ul>
                </div>
            </article>
        );
    }
}