import '../../assets/animations/howItWorks/image_import';
import './style.scss';

import React, { Component } from 'react';
import Lottie from 'react-lottie';

import animationData from '../../assets/animations/howItWorks/data.json';
import { Slider } from '../Slider';

export class HowItWorksSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            animationStopped: true,
        }

        this.trackScrolling = this.trackScrolling.bind(this);
        this.isBottom = this.isBottom.bind(this);
    }

    componentDidMount() {
        this.trackScrolling(true);
        
        document.addEventListener('scroll', this.trackScrolling);
    }

    trackScrolling(initCheck = false) {
        if (this.isBottom(initCheck)) {
            this.setState({ animationStopped: false });
            document.removeEventListener('scroll', this.trackScrolling);
        }
    }

    isBottom(initCheck) {
        const wrappedElement = document.getElementById('how-it-works');
        const offset = initCheck ? 0 : 230;
        return (wrappedElement.getBoundingClientRect().bottom + offset) <= window.innerHeight;
    }

    render() {
        const lottieOptions = {
            loop: false,
            autoplay: false, 
            animationData: animationData,
        };

        return (
            <section id="how-it-works">
                <div className="mobile-box-outer-wrapper">
                    <div className="container content">
                        <div className="row">
                            <h2 className="section-heading">
                                How It <span className="highlight">Works</span>
                            </h2>
                        </div>
                        <div className="row">
                            <div className="section-description">
                                <p>You only need to download the Washerly app which is available in the App Store as well as on Google Play. Sign up and you will be ready to choose the type of washes you want, exterior, interior, etc. A professional washer will arrive at the destination as fast as possible and you can track the whole process through the app.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="position-relative col-12 col-sm-12 col-md-6 col-lg-5">
                                <div className="how-it-works-banner">
                                    <Lottie 
                                        options={lottieOptions}
                                        isStopped={this.state.animationStopped}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-7">
                                <Slider/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="map-background left"></div>
                <div className="map-background right"></div>
            </section>
        );
    }
} 