import React, { Component } from 'react';
import {HeaderBanner} from "../components/HeaderBanner";
import rightImage from '../assets/images/header-icons/terms-of-use-right.svg';
import leftImage from '../assets/images/header-icons/terms-of-use-left.svg';
import {Header} from "../components/Header";

export class PrivacyPolicy extends Component {
    render() {
        return (
            <React.Fragment>
                <Header routeProps={this.props} />
                <HeaderBanner
                    leftIcon={leftImage}
                    rightIcon={rightImage}>
                    <h1>Privacy policy</h1>
                </HeaderBanner>

                <section className="white-section py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-md-12 text-container">
                                <h1>CARSHINE PRIVACY POLICY FOR CAR WASHERS AND USERS</h1>
                                <p>(in force from: 26.08.2019.)</p><br />
                                <p>As the owner of app <i>Carshine</i> the company registered under the laws of the Republic of Latvia sabiedrība ar ierobežotu atbildību (limited liability company „CARSHINE”, registration number: 40203220075 (hereafter – SIA CARSHINE), legal address: Rūpniecības st. 32B - 9, Riga, LV-1045, the Republic of Latvia, as well as companies in the group of companies of SIA CARSHINE, is a controller, which (by processing personal data) determines the purpouse and means of personal data processing.</p>
                                <p>This Privacy policy for car washers and users (hereafter – Privacy policy) is applicable to the use of app <i>Carshine</i>.</p>
                                <p>Within the meaning of this Privacy policy the car washer is a user of the app <i>Carshine</i>, which ensures the cleanness of vehicles of app <i>Carshine</i> users by using the Washing devices of SIA CARSHINE as determined in the General terms and conditions for car washers and users of app <i>Carshine</i>.</p>
                                <p>Within the meaning of this Privacy policy the user is a user of the app <i>Carshine</i>, who requests the washing of the vehicle by using the app <i>Carshine</i> as determined in the General terms and conditions for car washers and users of app <i>Carshine</i>.</p>
                                <p className="h3 border-highlight">1. Processed personal data in the app <i>Carshine</i></p>
                                <ul className="no-style">
                                    <li>1.1. The processed personal data of car washer:</li>
                                    <li>1.1.1. name, surname, e-mail address, phone number, address of the car washer;</li>
                                    <li>1.1.2. geographic location and driving route of the car washer;</li>
                                    <li>1.1.3. drives licence data, photo and personal identification document of the car washer;</li>
                                    <li>1.1.4. data on the criminal records and committed infringements of the car washer;</li>
                                    <li>1.1.5. identification data from the device in which the app <i>Carshine</i> is installed;</li>
                                    <li>1.1.6. information on the cases of dispute;</li>
                                    <li>1.1.7. information about the vehicle.</li>

                                    <li>1.2. Financial data related to the washing of the vehicle of the user does not considered to be personal data due to the fact that the car washer performs the washing of the vehicles within the scope of its business and professional activity.</li>

                                    <li>1.3. The processed personal data of the user:</li>
                                    <li>1.3.1. name, surname, e-mail address, phone number of the user;</li>
                                    <li>1.3.2. geographic location of the user;</li>
                                    <li>1.3.3. information on the payments in the app <i>Carshine</i>;</li>
                                    <li>1.3.4. identification data from the device in which the app <i>Carshine</i> is installed;</li>
                                    <li>1.3.5. information on the cases of dispute;</li>
                                    <li>1.3.6. information about the vehicle.</li>
                                </ul>
                                <p className="h3 border-highlight">2. The purpose of the processing of personal data</p>
                                <ul className="no-style">
                                    <li>2.1. SIA CARSHINE performs the collecting and processing of personal data in order to provide the possibility for users to receive the services of washing of vehicle</li>
                                    <li>2.2. The collected and processed information on contact information of car washers and users is collected and processed by SIA CARSHINE in order to notify on the available updates of the app <i>Carshine</i>.</li>
                                    <li>2.3. The geographic location of the car washer and user, the car washer`s driving rout, as well as the phone number is presented in the app <i>Carshine</i> in order to make the beginning of the vehicle washing process more effective. Data on the geographic location of the car washer and user are collected only when the app <i>Carshine</i> is activated, whilst the collection of such data is stopped when the app <i>Carshine</i> is closed.</li>
                                    <li>2.4. SIA CARSHINE may use the information of geographic location in order to resolve the issues related to the quality of providing vehicle washing services.</li>
                                    <li>2.5. The name, surname, phone number and e-mail address of the car washer and user is used for communication.</li>
                                    <li>2.6. SIA CARSHINE collects and processes the information on the payments with an aim to process the payments of the user`s on behalf of the car washer.</li>
                                    <li>2.7. SIA CARSHINE collects and compiles data related to the client support individually in each case and such data are stored for resolving of issues related to resolving of disputes and service quality.</li>
                                    <li>2.8. Information related to the drivers licence of the car washer and information on the criminal records and committed infringements of the car washer is collected and used by SIA CARSHINE in order to determine the conformity of car washer`s and its actions with the regulatory enactments and to find out the conformity of the person for provision of the services of washing of vehicles.</li>
                                    <li>2.9. The photo, name, vehicle licence plate number and vehicle model, as well as geographic location is displayed in the app <i>Carshine</i> in order to help the user to identify the car washer and its vehicle.</li>
                                </ul>
                                <p className="h3 border-highlight">3. Legal basis</p>
                                <ul className="no-style">
                                    <li>3.1. The personal data of the car washer and the user are processed in order to ensure the fulfilment of concluded agreement, as well as to ensure the service determined in the agreement to the user, and also for fulfilment of contractual and legal obligations of SIA CARSHINE. SIA CARSHINE collects and processes the data of the car washer and the user, which are provided during the process of installation and use of app <i>Carshine</i>. Without the consent form the car washer and the user to process the data on identification data and data on geographic location, the use of the app <i>Carshine</i> is not provided.</li>
                                    <li>3.2. The data on the criminal records and committed infringements of the car washer are processed in order to ensure the observation of legal obligations.</li>
                                    <li>3.3. SIA CARSHINE may process the personal data of the car washer and the user on the basis of legal interests investigating and discovering fraudulent payments.</li>
                                    <li>3.4. The car washer and the user accept the procession of personal data and this Privacy policy in full amount.</li>
                                </ul>
                                <p className="h3 border-highlight">4. Receivers of personal data</p>
                                <ul className="no-style">
                                    <li>4.1. The personal data of the car washer are disclosed only to the users whose order for washing of vehicle the car washer has accepted, whilst the personal data of the user are disclosed only to the car washer who has activated the app <i>Carshine</i>. In such case the user sees the name, vehicle number and vehicle model, phone number, photo and information on the geographic location of the car washer, whilst the car washer sees the name, phone number and geographic location of the user.</li>
                                    <li>4.2. After the provision of the user`s vehicle washing, the name, surname and phone number of the car washer and the user can be seen respectively to the user and the car washer in the order history in the account of the app <i>Carshine</i> – for as long as determined in this Privacy policy. The provision of such data to the car washer is necessary to resolve different issues related to the provision of services of vehicle washing, for example, to contact the user.</li>
                                    <li>4.3. Depending on the geographic location of the car washer and/or the user, the personal data of the car washer and/or the user may be disclosed to the partners and companies within the group of companies of SIA CARSHINE (local subsidiaries, representatives, branches, agents, representative offices, etc.). In such case the processing of personal data of the car washer and/or the user from the part of the partners and companies within the group of companies of SIA CARSHINE is carried out in accordance with the same provisions as determined in this Privacy policy.</li>
                                    <li>4.4. The feedback from the users on the quality of the vehicle washing service are anonymous and the car washers do not receive the names, surnames and phone numbers of the users who have provided a feedback or evaluation.</li>
                                </ul>
                                <p className="h3 border-highlight">5. Data protection and accessibility</p>
                                <ul className="no-style">
                                    <li>5.1. Any personal data obtained in relation to the provision of vehicle washing service are transferred and stored in Amazon Web Services data centre that are located within the territory of European Union member states. Only the authorised employees of SIA CARSHINE and partners and companies of the group of companies of SIA CARSHINE have access to the personal data and the data may be accessed with an aim to resolve issues, which are related to the use of SIA CARSHINE services (including also disputes related to the vehicle washing service). These authorised employees have access only to those personal data, which are necessary for provision of client service in the corresponding country.</li>
                                    <li>5.2. The data on the geographic location of the car washer and the user are processed in an anonymous form and are personalised only in cases when the information on the geographic location is necessary for resolving of issues related to disputes or fraud.</li>
                                    <li>5.3. The data used for statistic, research and/or scientific purposes are not identified and are anonymous.</li>
                                </ul>
                                <p className="h3 border-highlight">6. Accessibility of car washers and users to the data and editing of data</p>
                                <ul className="no-style">
                                    <li>6.1. The car washer and user may access and edit (renew) their personal data in the app <i>Carshine</i>.</li>
                                </ul>
                                <p className="h3 border-highlight">7. Storage of personal data</p>
                                <ul className="no-style">
                                    <li>7.1. The personal data of the car washer or the user are stored as long as the relevant car washer`s or the user`s account in the app <i>Carshine</i> is active. </li>
                                    <li>7.2. In case of closing of the account of the car washer of the user, the personal data in the data base of SIA CARSHINE are deleted in accordance with the provisions of this Privacy policy. The exception cases of such provisions are when the storage of data is necessary for accounting purposes, resolution of disputes or prevention of fraud.</li>
                                    <li>7.3. Information on the financial data of the car washers and the users related to the services of vehicle washing is stored for 1 (one) year after the last time of provision of vehicle washing.</li>
                                    <li>7.4. The obtained data related to the accounting purposes are stored for 7 (seven) years after the last time of provision of vehicle washing.</li>
                                    <li>7.5. In case if there is a suspicion on administrative violation or committed crime, fraudulent action or provision of false information, data is stored for 10 (ten) years.</li>
                                    <li>7.6. In case of disputes related to the payments in the app <i>Carshine</i>, the data shall be stored as long as the claim is satisfied or the end-term of the dispute claim has occurred.</li>
                                    <li>7.7. The deletion of the app <i>Carshine</i> from the car washer`s of the user`s device does not result in the deletion of personal data of the respective car washer or user.</li>
                                    <li>7.8. If the car washer`s or the user`s account in the app <i>Carshine</i> is not used at least 1 (one) year, SIA CARSHINE may request to confirm the use (activity) if the account in the app <i>Carshine</i>. If the respective car washer or the user does provide a negative reply on the request of SIA CARSHINE, the account in the app <i>Carshine</i> is closed. In such case the personal data is deleted in accordance with the provision determined in this Privacy policy.</li>
                                </ul>
                                <p className="h3 border-highlight">8. Deletion and transfer of data</p>
                                <ul className="no-style">
                                    <li>8.1. The request to SIA CARSHINE for deletion of personal data of the car washer or the user is executable only if SIA CARSHINE absolutely deletes the relevant either car washer`s or the user`s account in the app <i>Carshine</i>. In case of absolute deletion of account, respectively the car washer or the user will not be able to use the deleted account in the app <i>Carshine</i> in order to use the app <i>Carshine</i>.</li>
                                    <li>8.2. The requests received in the e-mail of SIA CARSHINE for deletion or transfer of personal data are answered within 1 (one) month by identifying the exact date of deletion or transfer of data.</li>
                                </ul>
                                <p className="h3 border-highlight">9. Dispute resolution</p>
                                <ul className="no-style">
                                    <li>9.1. The disputes related to the processing of personal data are resolved through the client support of SIA CARSHINE.</li>
                                    <li>9.2. The supervisory authority in the Republic of Latvia is Data State Inspectorate. It is possible to contact the Data State Inspectorate by writing an e-mail to the e-mail address: <i><a href="mailto:info@dvi.gov.lv">info@dvi.gov.lv</a></i>, identified in the web page <i><a target="_blank" href="www.dvi.gov.lv">www.dvi.gov.lv</a></i>.</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}