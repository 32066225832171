import React, { Component } from 'react';
import {HeaderBanner} from "../components/HeaderBanner";
import {CompanyNews} from "../components/CompanyNews";
import rightImage from '../assets/images/header-icons/blog-right.svg';
import leftImage from '../assets/images/header-icons/blog-left.svg';
import {Header} from "../components/Header";

export class Blog extends Component {
    render() {
        return (
            <React.Fragment>
                <Header routeProps={this.props} />
                <HeaderBanner
                    leftIcon={leftImage}
                    rightIcon={rightImage}>
                    <h1>Blog</h1>
                    <p>At Washerly we try to connect with you through our publications, we will keep you up to datewith the most recent developments. If you write about us, let us know here! <a href="mailto:xxx@gmail.com">xxx@gmail.com</a></p>
                </HeaderBanner>
                <CompanyNews/>
            </React.Fragment>
        );
    }
}
