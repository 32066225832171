import React, { Component } from 'react';
import {HeaderBanner} from "../components/HeaderBanner";
import VideoTutorialSlider from "../components/VideoTutorialSlider";
import rightImage from '../assets/images/header-icons/faq-right.svg';
import leftImage from '../assets/images/header-icons/faq-left.svg';
import {Link, NavLink} from "react-router-dom";
import {Header} from "../components/Header";

export class Faq extends Component {
    render() {
        return (
            <React.Fragment>
                <Header routeProps={this.props} />
                <HeaderBanner
                    leftIcon={leftImage}
                    rightIcon={rightImage}>
                    <h1>Faq</h1>
                    <p>Here you will find a list of frequently asked questions and the answers. If you still have further questions you can contact us at:</p>
                    <ul>
                        <li>How to Become a Washer</li>
                        <li>How to Create a User Account</li>
                        <li>Download the App for IOS</li>
                        <li>Download the App for Android</li>
                    </ul>
                </HeaderBanner>
                <VideoTutorialSlider/>

                <section className="white-section">
                    <div className="container pb-5">
                        <div className="row align-items-center">
                            <div className="col-md-4">
                                <div className="text-description-with-shadow">
                                    <div className="mask"></div>
                                    <h3 className="text-description-with-shadow--heading">General Problems</h3>
                                    <p>In this section, you can find the most common problems or issues as well as possible solutions.</p>
                                </div>
                            </div>
                            <div className="col-md-8 pl-5">
                                <div className="row">
                                    <div className="col-md-4 mb-2">
                                        <Link className="grey-link" to="/faq/how-to-become-a-washer">How to Become a Washer</Link>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <Link className="grey-link" to="/faq/washer-asking-other-payments">My Washer is asking for other payments</Link>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <Link className="grey-link" to="/faq/washer-did-not-do-a-good-job">My Washer didn’t do a good job</Link>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <Link className="grey-link" to="/faq/washer-did-not-show-up">My Washer didn’t show up</Link>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <Link className="grey-link" to="/faq/items-from-car-disappeared">An Item from My Car has Disappeared</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="white-section">
                    <div className="container pb-5">
                        <div className="row align-items-center">
                            <div className="col-md-4">
                                <div className="text-description-with-shadow">
                                    <div className="mask"></div>
                                    <h3 className="text-description-with-shadow--heading">Account And Payments</h3>
                                    <p>In this section, you can find all the solutions concerning your account status, management, registration and possible payment issues.</p>
                                </div>
                            </div>
                            <div className="col-md-8 pl-5">
                                <div className="row">
                                    <div className="col-md-4 mb-2">
                                        <a className="grey-link" href="https://dashboard.washerly.com/sign-up" target="_blank">Become a Washer</a>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <a className="grey-link" href="https://apps.apple.com/us/app/washerly/id1504687670" target="_blank">Download the App (IOS)</a>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <a className="grey-link" href="https://play.google.com/store/apps/details?id=com.carshine.washerly" target="_blank">Download the App (Android)</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        );
    }
}
