import React, { Component } from 'react';
import { useForm } from 'react-hook-form';
import emailIcon from '../../assets/images/at-icon.svg';
import './style.scss';

function ContactForm() {
    const { register, handleSubmit, errors, control, reset } = useForm({
        mode: "onChange"
    });

    const onSubmit = data => {
        fetch('https://washerly-prod.cleverapps.io/app/send-email-support', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((res) => {
            if(res.status === 201) {
                reset({
                    firstName: '',
                    lastName: '',
                    email: '',
                    subject: '',
                    message: '',
                }, {
                    errors: false,
                    dirtyFields: false,
                    dirty: false,
                    isSubmitted: false,
                    touched: false,
                    isValid: false,
                    submitCount: false,
                });
            }
        });
    };

    function validationStyleLogic(inputName) {
        const value = control.getValues()[inputName];
        if(errors[inputName]) {
            return 'invalid';
        } else if (!errors[inputName] && value !== undefined && value.length > 0) {
            return 'valid';
        } else {
            return 'untouched'
        }
    }

    return (
        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-4 mb-4">
                    <div className={`form-group ${validationStyleLogic('firstName')}`}>
                        <input type="text"
                               placeholder="First name"
                               name='firstName'
                               ref={register({ required: true })} />
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className={`form-group ${validationStyleLogic('lastName')}`}>
                        <input type="text"
                               placeholder="Last name"
                               name='lastName'
                               ref={register({ required: true })}  />
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className={`form-group ${validationStyleLogic('email')}`}>
                        <input type="text"
                               placeholder="E-mail"
                               name='email'
                               ref={register({
                                   required: true,
                                   pattern: /.+@.+\.[A-Za-z]+$/
                               })} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-4">
                    <div className={`form-group ${validationStyleLogic('subject')}`}>
                        <input type="text"
                               placeholder="Subject"
                               name='subject'
                               ref={register({ required: true })} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-4">
                    <div className={`form-group ${validationStyleLogic('message')}`}>
                        <textarea placeholder="Message"
                                  name='message'
                                  ref={register({ required: true })}></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type='submit' className="button button-primary">Send mail</button>
                </div>
            </div>
        </form>
    )
}

export class SendMail extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className="white-section" id="send-mail">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4 big-padding-right align-self-center">
                            <div className="small-description">
                                <h2 className="heading">Send <span className="color-lightgreen">Mail</span></h2>
                                <p>You can send us your questions, suggestions or anything you want related to Washerly by completing the following form and pressing ‘Send mail’.</p>
                            </div>
                            <div className="our-email pt-5 mt-5">
                                <img src={emailIcon} alt="" />
                                <a href="mailto:info@carshine.com">
                                    <span>Email us Directly</span>
                                    support@washerly.com
                                </a>
                            </div>
                        </div>
                        <div className="col-md-8 contact-form-wrapper">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
