import React, { Component } from 'react';
import {HeaderBanner} from "../components/HeaderBanner";
import {SendMail} from "../components/SendMail";
import {HaveQuestionsBanner} from "../components/HaveQuestionsBanner";
import rightImage from '../assets/images/header-icons/contact-right.svg';
import leftImage from '../assets/images/header-icons/contact-left.svg';
import {Header} from "../components/Header";

export class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <Header routeProps={this.props} />
                <HeaderBanner
                    leftIcon={leftImage}
                    rightIcon={rightImage}>
                    <h1>Contact</h1>
                    <p>Do you have a question for the Washerly team? We respond fast.</p>
                </HeaderBanner>
                <SendMail/>
                <HaveQuestionsBanner/>
            </React.Fragment>
        );
    }
}