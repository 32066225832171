import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

export class Faq extends Component {
    renderText() {
        switch(this.props.props.match.params.id) {
            case 'how-to-become-a-washer':
                return (
                    <div>
                        <h2 className="h2 mb-5">How to Become a Washer</h2>
                        <p>To became a Washer you need to fill out our registration form and verify yourself. After you are verified, you will get call from our manager and will schedule a Training.</p>
                    </div>
                );
            case 'washer-asking-other-payments':
                return (
                    <div>
                        <h2 className="h2 mb-5">Washer asking for extra payment</h2>
                        <p>All payments and tips are handled trough the App only, no cash payments are accepted and future washes must be done trough the App only, not in person with the Washer. Note: 100% off the tips goes to washer.</p>
                    </div>
                );
            case 'washer-did-not-do-a-good-job':
                return (
                    <div>
                        <h2 className="h2 mb-5">My Washer didn’t do a good job</h2>
                        <p>If you have any complaint about the service, please contact us immediately after the wash. Our washers always take pictures after the service is done and uploads pictures in the app for you to see. If we confirm, that the service was not provided for 100%, we will do our best to fix this situation in the future and give you some extra credits for the next Wash you order. </p>
                    </div>
                );
            case 'washer-did-not-show-up':
                return (
                    <div>
                        <h2 className="h2 mb-5">My Washer didn’t show up, what to do?</h2>
                        <p>A If your washer didn’t arrive for some reason, you will get a 100% refund issued back to your card.</p>
                    </div>
                );
            case 'items-from-car-disappeared':
                return (
                    <div>
                        <h2 className="h2 mb-5">An Item from My Car has Disappeared, what to do?</h2>
                        <p>Our Washers are always doing their best to organise everything and leave your stuff in the secure place in your car, if for some reason something is missing, please contact us immediately. Also it’s safer in you never leave your belongings in the car. </p>
                    </div>
                );
            default:
        }
    }

    render() {
        return (
            <section className="white-section py-5">
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="text-description-with-shadow">
                                <div className="mask"></div>
                                <h3 className="text-description-with-shadow--heading">General Problems</h3>
                            </div>
                            <ul className="mt-4 faq-side-nav">
                                <li><NavLink to="/faq/how-to-become-a-washer" activeClassName='active'>How to Become a Washer</NavLink></li>
                                <li><NavLink to="/faq/washer-asking-other-payments" activeClassName='active'>My Washer is asking for other payments</NavLink></li>
                                <li><NavLink to="/faq/washer-did-not-do-a-good-job" activeClassName='active'>My Washer didn’t do a good job</NavLink></li>
                                <li><NavLink to="/faq/washer-did-not-show-up" activeClassName='active'>My Washer didn’t show up</NavLink></li>
                                <li><NavLink to="/faq/items-from-car-disappeared" activeClassName='active'>An Item from My Car has Disappeared</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-md-8 pl-5 text-container">
                            {this.renderText()}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
} 
