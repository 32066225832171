import React, { Component } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import './style.scss';

export class BecomeAWasherForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <form>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="form-group untouched">
                            <input type="text" placeholder="First name" />
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="form-group untouched">
                            <input type="text" placeholder="Last name" />
                        </div>
                    </div>
                    <div className="col-md-12 mb-4">
                        <div className="form-group valid">
                            <input type="text" placeholder="E-mail" value="example@email.com" />
                        </div>
                    </div>
                    <div className="col-md-12 mb-4">
                        <div className="form-group invalid">
                            <IntlTelInput preferredCountries={['lv', 'us']} />
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="form-group untouched">
                            <input type="text" placeholder="Country" />
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="form-group untouched">
                            <input type="text" placeholder="State" />
                        </div>
                    </div>
                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                        <button className="button button-primary">Send request</button>
                    </div>
                </div>
            </form>
        );
    }
}
