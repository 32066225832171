import React, { Component } from 'react';
import {BannerSection} from "../components/BannerSection/index";
import {HowItWorksSection} from "../components/HowItWorksSection/index";
import {InformationSection} from "../components/InformationSection/index";
import {Header} from "../components/Header";

export class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <Header routeProps={this.props} />
                <BannerSection/>
                <HowItWorksSection/>
                {/*<InformationSection/>*/}
            </React.Fragment>
        );
    }
}