import React, { useState, useCallback, useEffect } from "react";
import Swiper from "react-id-swiper";
import placeholderThumbnail from '../../assets/images/placeholder/video-thumbnail.jpg';
import './style.scss';

const VideoTutorialSlider = () => {

    // Fake data
    const slides = [
        {title: 'Available Soon 1', url: '#', image: placeholderThumbnail},
        {title: 'Available Soon 2', url: '#', image: placeholderThumbnail},
        {title: 'Available Soon 3', url: '#', image: placeholderThumbnail},
        {title: 'Available Soon 4', url: '#', image: placeholderThumbnail},
        {title: 'Available Soon 5', url: '#', image: placeholderThumbnail},
    ];

    const [swiper, updateSwiper] = useState(null);
    const [currentIndex, updateCurrentIndex] = useState(0);

    const params = {
        slidesPerView: 2,
        spaceBetween: 30,
        getSwiper: updateSwiper
    };

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [
        swiper
    ]);

    useEffect(() => {
        if (swiper !== null) {
            swiper.on("slideChange", updateIndex);
        }

        return () => {
            if (swiper !== null) {
                swiper.off("slideChange", updateIndex);
            }
        };
    }, [swiper, updateIndex]);

    return (
        <section id="tutorial-slider-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 pr-5">
                        <div className="small-description">
                            <h2 className="heading">Video <span className="color-lightgreen">Tutorials</span></h2>
                            <p>
                                Are you having trouble using the Washerly app? Perhaps you don’t know how to start using it, don’t worry, we got you covered with this brief but explanatory video that will guide you through our app.</p>
                            <div className="d-flex align-items-center mt-5">
                                <button className={`slider-arrow slider-arrow-left mr-3 ${currentIndex === 0 ? 'swiper-button-disabled' : ''}`} onClick={goPrev}></button>
                                <button className={`slider-arrow slider-arrow-right ${currentIndex === (slides.length - 2) ? 'swiper-button-disabled' : ''}`} onClick={goNext}></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 tutorial-slider-content">
                        <div className="row">
                            <div className="col-md-12 overflow-hidden pb-3">
                                <Swiper {...params} containerClass={'swiper-container swiper-container--videos'}>
                                    {slides.map((item, key) =>
                                        <div key={key}>
                                            <a href={item.url} className="tutorial-entry">
                                                <span>{item.title}</span>
                                                <img src={item.image} alt="" />
                                            </a>
                                        </div>
                                    )}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VideoTutorialSlider;
