import React, { useState, useCallback, useEffect } from "react";
import Swiper from "react-id-swiper";
import {BlogArticleMinimal} from "../BlogArticleMinimal";

const OtherNewsSlider = () => {

    // Fake data
    let slides = [
        {id: 1},
        {id: 2},
        {id: 3},
        {id: 4},
    ];

    const [swiper, updateSwiper] = useState(null);
    const [currentIndex, updateCurrentIndex] = useState(0);

    const params = {
        slidesPerView: 2,
        spaceBetween: 120,
        getSwiper: updateSwiper
    };

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [
        swiper
    ]);

    useEffect(() => {
        if (swiper !== null) {
            swiper.on("slideChange", updateIndex);
        }

        return () => {
            if (swiper !== null) {
                swiper.off("slideChange", updateIndex);
            }
        };
    }, [swiper, updateIndex]);

    return (
        <section className="white-section white-section--with-top-shadow py-5">
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-4 pr-5">
                        <div className="small-description">
                            <h2 className="heading">Other <span className="color-lightgreen">News</span></h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <div className="d-flex align-items-center mt-5">
                                <button className={`slider-arrow slider-arrow-left mr-3 ${currentIndex === 0 ? 'swiper-button-disabled' : ''}`} onClick={goPrev}></button>
                                <button className={`slider-arrow slider-arrow-right ${currentIndex === (slides.length - 2) ? 'swiper-button-disabled' : ''}`} onClick={goNext}></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row justify-content-between overflow-hidden">
                            <div className="col-md-12">
                                <Swiper {...params}>
                                    {slides.map((item, key) =>
                                        <div key={key}>
                                            <BlogArticleMinimal/>
                                        </div>
                                    )}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OtherNewsSlider;
