import React, { Component } from 'react';

export class BlogArticleMinimal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <article className="blog-article blog-article--minimal">
                <div className="blog-article-details">
                    <span className="date">02 May 2018</span>
                    <a href="#" className="title">Why yo should switch to moile car wash?</a>
                    <p className="m-0">Besides, random text risks to be unintendedly humorous or
                        offensive, an unacceptable risk in corporate environments.
                        Lorem ipsum and its many variants have been employed since
                        the early 1960ies, and quite likely since the sixteenth century.</p>
                </div>
                <div className="mask"></div>
            </article>
        );
    }
}