import React, { Component } from 'react';

import './style.scss';

const slideContent = [[
    ['Washerly allows you to choose between a multitude of washing options. If you have downloaded the app and registered, you can easily select the package that best fits your needs and we will send a professional washer to take care of your car. We don’t use water and we don’t leave any waste behind. Additionally, customers have the ability to see each step of the washing process. What are you waiting for? Download the app now and enjoy your freshly washed shiny car!'],
]];

export class Slider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeSlideIndex: 0,
        }

        this.prevSlide = this.prevSlide.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
        this.jumpToSlide = this.jumpToSlide.bind(this);
    }

    componentDidMount() {

    }


    prevSlide() {
        this.setState(prevState => {
            return {
                activeSlideIndex: prevState.activeSlideIndex === 0 ? slideContent.length - 1 : prevState.activeSlideIndex - 1,
            }
        })
    }

    nextSlide() {
        this.setState(prevState => {
            return {
                activeSlideIndex: prevState.activeSlideIndex === slideContent.length - 1 ? 0: prevState.activeSlideIndex + 1,
            }
        })
    }

    jumpToSlide(slideIndex) {
        this.setState({ activeSlideIndex: slideIndex });
    }

    render() {
        return (
            <div className="carousel">
                <div className="row carousel-heading">
                    <div className="col-12 col-md-8">
                        <h4 className="carousel-title">Choose Options Wash</h4>
                    </div>
                    <div className="col-4 carousel-controls">
                        <div className="carousel__navigation_button prev" onClick={this.prevSlide}></div>
                        <div className="carousel__navigation_button next" onClick={this.nextSlide}></div>
                    </div>
                </div>
                <div className="row carousel__slides_outer_wrapper">
                    <div 
                        className="carousel__slides"
                        style={{
                            'transform': `translate3d(-${this.state.activeSlideIndex * 100}%, 0px, 0px)`
                        }}
                    >
                        {slideContent.map((slide, index) => (
                            <div className="carousel__slide">
                                {slide.map(paragraph => (<p>{paragraph}</p>))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="row carousel-indicators">
                    {slideContent.map((_, index) => (
                        <span 
                            className={`carousel__indicator ${this.state.activeSlideIndex === index && "active" || ""}`}
                            onClick={() => this.jumpToSlide(index)}
                        ></span>
                    ))}
                </div>
            </div>
        );
    }
}