import React from 'react';
import facebook from '../../assets/images/social-icons/soc-facebook.svg';
import twitter from '../../assets/images/social-icons/soc-twitter.svg';
import instagram from '../../assets/images/social-icons/soc-instagram.svg';
import placeholderThumbnail1 from '../../assets/images/placeholder/blog-1.png';
import placeholderThumbnail2 from '../../assets/images/placeholder/blog-2.png';
import placeholderThumbnail3 from '../../assets/images/placeholder/blog-3.png';

export class BlogEntrySection extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className="white-section py-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-md-6 mb-5 text-container pr-4">
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                    <h2 className="h1">Why you should switch to Mobile Car Wash?</h2>
                                </div>
                                <div className="col-md-4">
                                    <ul className="social-networks">
                                        <li><a href="#"><img src={facebook} alt="Facebook" /></a></li>
                                        <li><a href="#"><img src={twitter} alt="Twitter" /></a></li>
                                        <li><a href="#"><img src={instagram} alt="Instagram" /></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blog-tags mt-4 mb-5">
                                        <span className="tag">Wash</span>
                                        <span className="tag">Car</span>
                                    </div>
                                    <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and
                                        printing in place of English to emphasise design elements over content. It’s
                                        also called placeholder (or filler) text. It’s a convenient tool for mock-ups.
                                        It helps to outline the visual elements of a document or presentation, eg
                                        typography, font, or layout. Lorem ipsum is mostly a part of a Latin text
                                        by the classical author and philosopher Cicero. Its words and letters have
                                        been changed by addition or removal, so to deliberately render its content
                                        nonsensical; it’s not genuine, correct, or comprehensible Latin anymore.
                                        While lorem ipsum’s still resembles classical Latin, it actually has no
                                        meaning whatsoever. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="image-container">
                                <img src={placeholderThumbnail1} alt="" />
                                <img src={placeholderThumbnail2} alt="" />
                                <img src={placeholderThumbnail3} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-container">
                            <h2 className="h3">Lorem ipsum is a pseudo-Latin text used in web design, typography</h2>
                            <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and
                                printing in place of English to emphasise design elements over content. It’s
                                also called placeholder (or filler) text. It’s a convenient tool for mock-ups.
                                It helps to outline the visual elements of a document or presentation, eg
                                typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by
                                the classical author and philosopher Cicero. Its words and letters have been
                                changed by addition or removal, so to deliberately render its content nonsensical;
                                it’s not genuine, correct, or comprehensible Latin anymore. While lorem ipsum’s
                                still resembles classical Latin, it actually has no meaning whatsoever. </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}