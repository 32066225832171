import React, { Component } from 'react';
import './style.scss';
import {BlogArticle} from "../BlogArticle";
import Swiper from 'react-id-swiper';

export class CompanyNews extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className="white-section" id="company-news-section">
                <div className="container container--company-news-overflow">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="small-description">
                                <h2 className="heading">Company <span className="color-lightgreen">News</span></h2>
                                <p>Here you can find the latest news and updates about Washerly</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <Swiper containerClass={'swiper-container swiper-container--news'} {...{
                                slidesPerView: 3,
                                slidesPerGroup: 3,
                                spaceBetween: 30,
                                navigation: {
                                    nextEl: '.button-next',
                                    prevEl: '.button-prev',
                                },
                                renderPrevButton: () => <button className="button-prev slider-arrow slider-arrow-left slider-arrow--reverse mr-3">Previous news</button>,
                                renderNextButton: () => <button className="button-next slider-arrow slider-arrow-right slider-arrow--reverse">Next news</button>,
                            }}>
                                <div><BlogArticle/></div>
                                <div><BlogArticle/></div>
                                <div><BlogArticle/></div>
                                <div><BlogArticle/></div>
                                <div><BlogArticle/></div>
                                <div><BlogArticle/></div>
                                <div><BlogArticle/></div>
                                <div><BlogArticle/></div>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}