import React, { Component } from 'react';
import {HeaderBanner} from "../components/HeaderBanner";
import rightImage from '../assets/images/header-icons/rules-right.svg';
import leftImage from '../assets/images/header-icons/rules-left.svg';
import {Header} from "../components/Header";

export class Rules extends Component {
    render() {
        return (
            <React.Fragment>
                <Header routeProps={this.props} />
                <HeaderBanner
                    leftIcon={leftImage}
                    rightIcon={rightImage}>
                    <h1>Rules</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </HeaderBanner>

                <section className="white-section py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-md-12 text-container">
                                <h1 className="h1">Mobile Application End User License Agreement</h1>
                                <h5 className="last-modified mb-5">Last modified: 10.27</h5>
                                <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and
                                    printing in place of English to emphasise design elements over content. It’s also
                                    called placeholder (or filler) text. It’s a convenient tool for mock-ups. It helps
                                    to outline the visual elements of a document or presentation, eg typography, font,
                                    or layout. Lorem ipsum is mostly a part of a Latin text by the classical author
                                    and philosopher Cicero. Its words and letters have been changed by addition or
                                    removal, so to deliberately render its content nonsensical; it’s not genuine,
                                    correct, or comprehensible Latin anymore. While lorem ipsum’s still resembles
                                    classical Latin, it actually has no meaning whatsoever. </p>
                                <p>In a professional context it often happens that private or corporate clients corder
                                    a publication to be made and presented with the actual content still not being
                                    ready. Think of a news blog that’s filled with content hourly on the day of
                                    going live. However, reviewers tend to be distracted by comprehensible content,
                                    say, a random text copied from a newspaper or the internet. The are likely
                                    to focus on the text, disregarding the layout and its elements. </p>
                                <h2 className="h3 border-highlight">Lorem ipsum is a pseudo-Latin text used in web design, typography</h2>
                                <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and
                                    printing in place of English to emphasise design elements over content. It’s
                                    also called placeholder (or filler) text. It’s a convenient tool for mock-ups.
                                    It helps to outline the visual elements of a document or presentation, eg
                                    typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by
                                    the classical author and philosopher Cicero. Its words and letters have been
                                    changed by addition or removal, so to deliberately render its content nonsensical;
                                    it’s not genuine, correct, or comprehensible Latin anymore. While lorem ipsum’s
                                    still resembles classical Latin, it actually has no meaning whatsoever. </p>
                                <h2 className="h3 border-highlight">Lorem ipsum is a pseudo-Latin text used in web design, typography</h2>
                                <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and
                                    printing in place of English to emphasise design elements over content. It’s
                                    also called placeholder (or filler) text. It’s a convenient tool for mock-ups.
                                    It helps to outline the visual elements of a document or presentation, eg
                                    typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by
                                    the classical author and philosopher Cicero. Its words and letters have been
                                    changed by addition or removal, so to deliberately render its content nonsensical;
                                    it’s not genuine, correct, or comprehensible Latin anymore. While lorem ipsum’s
                                    still resembles classical Latin, it actually has no meaning whatsoever. </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="white-section white-section--with-top-shadow py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-md-12 text-container">
                                <h2 className="h3">Lorem ipsum is a pseudo-Latin text used in web design, typography</h2>
                                <ul className="list-with-circle mt-5">
                                    <li>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and printing in place of English to emphasise design elements over content. </li>
                                    <li>It’s also called placeholder (or filler) text. It’s a convenient tool for mock-ups. It helps to outline the visual elements of a document or presentation, eg typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by the classical author and philosopher Cicero. </li>
                                    <li>Its words and letters have been changed by addition or removal, so to deliberately render its content nonsensical; it’s not genuine, correct, or comprehensible Latin anymore. While lorem ipsum’s still resembles classical Latin, it actually has no meaning whatsoever. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}