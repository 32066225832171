import React, { Component } from 'react';
import './style.scss';
import {BecomeAWasherForm} from "../BecomeAWasherForm";
import timerIcon from '../../assets/images/timer.svg';
import calendarIcon from '../../assets/images/calendar.svg';

export class BecomeAWasherSection extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className="white-section" id="become-a-washer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="become-a-washer-container">
                                <div className="row">
                                    <div className="col-md-5">
                                        <BecomeAWasherForm/>
                                    </div>
                                    <div className="col-md-7 pl-5 become-a-washer-details">
                                        <p>Lorem ipsum is a pseudo-Latin text used in web design, typography,
                                            layout, and printing in place of English to emphasise design elements
                                            over content. It’s also called placeholder (or filler) text. It’s a
                                            convenient tool for mock-ups. It helps to outline the visual elements
                                            of a document or presentation, eg typography, font, or layout. </p>

                                        <ul className="list-with-icon mt-5">
                                            <li>
                                                <img src={calendarIcon} alt="" />
                                                <div className="content">
                                                    <h4 className="color-lightgreen">Choose your hours</h4>
                                                    <p>Lorem ipsum is a pseudo-Latin text used in web design,
                                                        typography, layout, and printing in place of English to emphasise
                                                        design elements over content. </p>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={timerIcon} alt="" />
                                                <div className="content">
                                                    <h4 className="color-lightgreen">Get paid fast</h4>
                                                    <p>Lorem ipsum is a pseudo-Latin text used in web design,
                                                        typography, layout, and printing in place of English to emphasise
                                                        design elements over content. </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}